import React from "react";

function Logo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 209.51 126.44"
      version="1.1"
      viewBox="0 0 209.51 126.44"
      xmlSpace="preserve"
    >
      <path
        fill="#DD3C29"
        d="M103.84 125.68c-.25-.07-25.7-6.79-50.81-17.44C18.17 93.46.5 78.31.5 63.22c0-15.09 17.67-30.24 52.52-45.02C78.13 7.55 103.58.82 103.84.76l.92-.24.92.24c.25.07 25.7 6.79 50.81 17.44 34.85 14.78 52.52 29.93 52.52 45.02 0 15.09-17.67 30.24-52.52 45.02-25.11 10.65-50.56 17.37-50.81 17.44l-.92.24-.92-.24z"
      ></path>
      <path
        fill="none"
        stroke="#DD3C29"
        strokeMiterlimit="10"
        d="M103.84 125.68c-.25-.07-25.7-6.79-50.81-17.44C18.17 93.46.5 78.31.5 63.22c0-15.09 17.67-30.24 52.52-45.02C78.13 7.55 103.58.82 103.84.76l.92-.24.92.24c.25.07 25.7 6.79 50.81 17.44 34.85 14.78 52.52 29.93 52.52 45.02 0 15.09-17.67 30.24-52.52 45.02-25.11 10.65-50.56 17.37-50.81 17.44l-.92.24-.92-.24z"
      ></path>
      <path
        fill="none"
        stroke="#FFF"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M104.6 122.78c-.25-.07-25.59-6.78-50.4-17.3C21.03 91.42 3.5 76.8 3.5 63.22c0-13.58 17.53-28.2 50.7-42.26 24.54-10.41 49.36-17.02 50.41-17.3l.15-.04.16.04c1.04.27 25.81 6.87 50.4 17.3 33.16 14.07 50.69 28.68 50.69 42.26 0 13.58-17.53 28.2-50.69 42.26-24.87 10.55-50.15 17.23-50.4 17.3l-.16.04-.16-.04z"
      ></path>
      <path fill="none" d="M0.5 0.52H209.01V125.92999999999999H0.5z"></path>
      <g fill="#FFF">
        <path d="M55.77 63.22c0-16.05 7.71-24.93 17.91-24.93 5.03 0 9.99 2.41 12.6 7.3l-4.27 5.03h-.76c-1.86-3.65-4.13-5.44-6.96-5.44-2.62 0-4.75 2.07-4.75 5.58v25.07c0 3.72 2.48 5.44 4.82 5.44 3.03 0 5.72-1.72 7.3-4.68h.83l3.79 4.2c-2.55 4.89-7.58 7.37-12.6 7.37-10.19-.01-17.91-8.96-17.91-24.94"></path>
        <path d="M87.99 70.47L87.17 73.22 77.56 73.22 77.56 53.96 82.88 53.96 82.88 70.47z"></path>
        <path d="M88.43 53.96L97.98 53.96 98.81 56.71 93.74 56.71 93.74 61.77 97.13 61.77 97.13 64.52 93.74 64.52 93.74 70.47 99.28 70.47 98.45 73.22 88.43 73.22z"></path>
        <path d="M116.75 53.96L116.75 73.22 111.41 73.22 111.41 62.38 108.03 73.22 106.27 73.22 102.8 62.32 102.8 73.22 99.96 73.22 99.96 53.96 105.66 53.96 108.41 62.79 111.16 53.96z"></path>
        <path d="M117.93 53.96L127.48 53.96 128.31 56.71 123.24 56.71 123.24 61.77 126.63 61.77 126.63 64.52 123.24 64.52 123.24 70.47 128.78 70.47 127.95 73.22 117.93 73.22z"></path>
        <path d="M141.05 53.96L141.05 73.22 138.44 73.22 132.3 62.02 132.3 73.22 129.46 73.22 129.46 53.96 134.06 53.96 138.21 61.39 138.21 53.96z"></path>
        <path d="M141.54 70.8l1.65-2.07h.33c1.07 1.24 2.09 2.07 3.63 2.07 1.21 0 2.04-.58 2.04-1.71 0-2.86-7.1-3.74-7.1-9.69 0-3.05 2.09-5.78 6.03-5.78 1.79 0 3.52.58 4.79 1.93l-1.43 2.26h-.3c-.88-.99-1.87-1.43-3.03-1.43-1.07 0-1.84.47-1.84 1.43 0 2.48 7.43 3.6 7.43 9.63 0 3.91-2.72 6.11-6.36 6.11-2.56 0-4.57-1.1-5.84-2.75"></path>
        <g>
          <path d="M92.37 77.89L93.19 77.89 93.19 79.34 93.79 79.34 95.59 77.89 96.76 77.89 94.51 79.67 96.94 81.52 95.71 81.52 93.77 80.01 93.19 80.01 93.19 81.52 92.37 81.52z"></path>
          <path d="M101.71 78.21c.15.17.23.4.26.77.02.17.02.37.02.77 0 .91-.08 1.26-.36 1.5-.2.18-.46.26-.91.29-.17.01-.81.02-1.31.02-1.08 0-1.37-.03-1.68-.2-.23-.13-.39-.38-.43-.74-.03-.24-.04-.44-.04-1.03 0-.85.08-1.19.33-1.42.19-.17.44-.26.9-.29.18-.02.71-.03 1.36-.03 1.26 0 1.6.06 1.86.36m-3.61 1.5c0 .71.05.94.25 1.04.2.11.37.13 1.38.13.91 0 1.14-.04 1.28-.2.12-.14.16-.37.16-.99 0-1.14-.01-1.15-1.54-1.15-1.51-.01-1.53.01-1.53 1.17m.41-2.77h.85v.53h-.85v-.53zm1.38 0h.85v.53h-.85v-.53z"></path>
          <path d="M104.23 78.57L102.56 78.57 102.56 77.89 106.71 77.89 106.71 78.57 105.05 78.57 105.05 81.52 104.23 81.52z"></path>
          <path d="M108.83 78.57L107.16 78.57 107.16 77.89 111.31 77.89 111.31 78.57 109.65 78.57 109.65 81.52 108.83 81.52z"></path>
          <path d="M116.99 81.14c-.25.29-.56.38-1.45.38-1.3 0-1.81-.04-2.06-.16-.35-.16-.47-.41-.47-.98 0-.73.19-.95.87-.96-.33-.16-.44-.34-.44-.72 0-.46.14-.69.5-.77.17-.04.56-.06 1.21-.06 1.55 0 1.77.13 1.77 1.03 0 .01 0 .06-.01.1h-.73v-.09c.01-.16-.06-.32-.16-.37-.12-.06-.13-.06-.8-.08-.8.01-.8.01-.93.07-.07.03-.12.13-.12.25 0 .16.07.25.34.39l2 1.07c.02-.1.02-.17.02-.3 0-.14 0-.19-.02-.29h.72c.01.05.01.09.01.16 0 .31-.02.53-.06.77l.76.41-.24.54-.71-.39zm-2.69-1.42c-.05-.01-.08-.01-.1-.01-.12 0-.23.05-.33.15-.07.08-.11.25-.11.48 0 .25.05.38.18.45.14.08.56.12 1.26.12.75 0 1.02-.03 1.14-.13l-2.04-1.06z"></path>
          <path d="M119.88 77.89L120.7 77.89 120.7 79.33 123.49 79.33 123.49 77.89 124.31 77.89 124.31 81.52 123.49 81.52 123.49 80.01 120.7 80.01 120.7 81.52 119.88 81.52z"></path>
          <path d="M129.63 77.89v2.29c0 .69-.11.98-.45 1.17-.3.18-.6.21-1.86.21-1.23 0-1.6-.08-1.86-.38-.18-.21-.23-.46-.23-1.13v-2.16h.82v1.9c0 .48 0 .48.03.64.06.39.27.46 1.33.46.48 0 .92-.02 1.04-.05.21-.05.32-.19.35-.41.01-.07.01-.07.02-.57V77.9h.81zM131.24 80.37v.04c0 .23.04.36.14.42.13.08.4.11.92.11.44 0 .72-.02 1.1-.07.2-.03.3-.07.37-.14.07-.09.11-.2.11-.31 0-.21-.09-.32-.33-.37-.15-.03-.15-.03-1.12-.04h-.51c-.75 0-1.04-.06-1.24-.25-.17-.15-.25-.38-.25-.7 0-.4.1-.74.27-.89.24-.23.78-.32 1.95-.32.71 0 1.09.03 1.34.1.44.13.59.38.59.94v.07h-.8c-.01-.21-.06-.32-.17-.38-.15-.07-.49-.11-.88-.11-.67 0-1.14.04-1.29.13-.13.06-.19.19-.19.35 0 .16.05.26.15.32.12.07.32.09.88.09h.6c1.06 0 1.39.06 1.61.31.15.16.22.4.22.76 0 .38-.08.64-.25.82-.23.23-.69.31-1.88.31-1.27 0-1.67-.06-1.9-.28-.16-.16-.23-.38-.23-.72v-.19h.79z"></path>
          <path d="M135.53 77.89L136.79 77.89 138.46 80.62 140.12 77.89 141.37 77.89 141.37 81.52 140.57 81.52 140.6 78.53 138.77 81.52 138.13 81.52 136.31 78.53 136.33 81.52 135.53 81.52z"></path>
          <path d="M143.29 80.8l-.39.72h-.92l2.03-3.63h1.11l2 3.63h-.89l-.4-.72h-2.54zm1.26-2.32l-.95 1.74h1.91l-.96-1.74z"></path>
          <path d="M147.56 77.89L148.83 77.89 151.49 80.91 151.47 77.89 152.25 77.89 152.25 81.52 150.98 81.52 148.34 78.51 148.35 81.52 147.56 81.52z"></path>
        </g>
      </g>
    </svg>
  );
}

export default Logo;
