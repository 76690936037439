import PropTypes from "prop-types";
import React from "react";

import Header from "./common/Header";
import Footer from "./common/Footer";

function PageLayout({ children }) {
  return (
    <div className="flex flex-col min-h-screen text-gray-900 gradient">
      <Header />

      <main className="flex-1 w-full max-w-6xl px-2 py-8 mx-auto md:px-8 md:py-16">
        {children}
      </main>

      <Footer />
    </div>
  );
}

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageLayout;
