import React from "react"
import { useStaticQuery, graphql } from "gatsby"

function Oppettider() {
  const data = useStaticQuery(graphql`
    {
      wpPage(slug: { eq: "oppettider" }) {
        id
        content
      }
    }
  `)
  const page = data.wpPage
  return (
    <div className="text-center">
      <div>
        <h5 className="font-bold acme uppercase">Öppettider</h5>
        <div dangerouslySetInnerHTML={{ __html: page.content }}></div>
      </div>
    </div>
  )
}

export default Oppettider
