import React from "react";
import Oppettider from "./Oppettider";
import { SocialIcon } from "react-social-icons";
import CookieConsent from "react-cookie-consent";
function Footer() {
  return (
    <div className="bg-transparent md:bg-pink-100 text-cherry text-xs z-0">
      <footer
        id="kontakt"
        className="max-w-4xl py-6 px-8 mx-auto flex flex-wrap flex-col md:flex-row justify-between items-center text-sm"
      >
        <div className="w-1/3 flex-1 order-2 md:order-first">
          <nav className="md:-ml-2 pl-4 md:pl-0 text-center md:text-left">
            {[
              {
                url: `https://www.instagram.com/clemenskott`,
              },
              {
                url: `https://www.facebook.com/clemenskott`,
              },
            ].map((link) => (
              <SocialIcon
                className="block text-white md:inline-block "
                key={link.title}
                url={link.url}
                fgColor="#DC3B28"
                bgColor="transparent"
                style={{ height: 40, width: 40 }}
                target="_blank"
                rel="noreferrer noopener"
              >
                {link.title}
              </SocialIcon>
            ))}
          </nav>
        </div>
        <div className="w-1/3 flex-1">
          <Oppettider />
        </div>
        <div className="w-1/3 flex-1 text-center md:text-right py-4 md:py-0">
          Gibraltargatan 6<br />
          info@clemenskott.se
          <br />
          040-22 22 96
        </div>
      </footer>
      <div className="text-center py-2 opacity-50">
        Hemsida av{" "}
        <a href="https://www.olleburlin.se" className="font-bold">
          Olle Burlin
        </a>
      </div>
      <CookieConsent
        location="bottom"
        buttonText="OK!"
        cookieName="gatsby-gdpr-google-analytics"
        style={{ background: "#ffffff", color: "#0d0d0d" }}
        buttonStyle={{
          background: "#0d0d0d",
          color: "#fafafa",
          fontSize: "13px",
          borderRadius: "4px",
        }}
        expires={150}
      >
        Denna sidan använder Google Analytics som placerar en cookie på din
        dator{" "}
      </CookieConsent>
    </div>
  );
}

export default Footer;
