import { Link } from "gatsby"
import React, { useState } from "react"
import Logo from "./Logo"

function Header() {
  const [isExpanded, toggleExpansion] = useState(false)

  return (
    <header className="text-cherry acme uppercase bg-white ">
      <div className="flex flex-wrap py-4 items-center px-6 mx-auto md:pt-4 md:pb-2 max-w-6xl justify-between md:justify-center">
        <div className="md:w-32 lg:w-40 w-24">
          <Link to="/">
            <Logo />
          </Link>
        </div>

        <button
          className="flex items-center px-3 py-2  border border-white rounded md:hidden"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className="w-6 h-6 fill-current"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
      <div className="flex md:justify-center w-full md:pb-0">
        <nav
          className={`${
            isExpanded ? `block mb-4` : `hidden`
          } md:block md:items-start md:w-auto pl-6 md:pl-0 md:pb-4`}
        >
          {[
            {
              route: `/restaurang/`,
              title: `Meny`,
            },
            {
              route: `/#pask`,
              title: `Påsk`,
            },
            {
              route: `/gas/`,
              title: `Gås`,
            },
            {
              route: `/jul-nyar/`,
              title: `Jul / Nyår`,
            },

            {
              route: `/#om-oss`,
              title: `Om oss`,
            },
            {
              route: `/#kontakt`,
              title: `Kontakt`,
            },
          ].map(link => (
            <Link
              className="block mt-4 text-lg md:text-sm font-normal md:inline-block md:mt-0 mb-2 md:mb-0 md:mx-6 hover:text-gray-900"
              key={link.title}
              to={link.route}
              activeClassName="text-gray-900"
              onClick={() => toggleExpansion(!isExpanded)}
            >
              {link.title}
            </Link>
          ))}
        </nav>
      </div>
    </header>
  )
}

export default Header
